'use strict';

document.addEventListener('DOMContentLoaded', () => {
    const banners = document.querySelectorAll('.js-citrus-home-banner');
    banners.forEach(function getBanners(banner) {
        banner.addEventListener('click', function sendClick(event) {
            event.preventDefault();
            const url = this.getAttribute('href');
            if (url) {
                window.location.href = url;
            }
            const backendUrl = this.getAttribute('data-click-url');
            fetch(backendUrl, {
                method: 'GET'
            });
        });
    });
});

function handleCictusClick(el) {
    const backendUrl = el.closest('.product-tile').data('click-url');
    if (backendUrl) {
        $.ajax({
            url: backendUrl,
            method: 'GET'
        });
    }
}

module.exports = {
    handleCictusClick: handleCictusClick
};
