const processInclude = require("base/util");

$(document).ready(() => {
  processInclude(require("base/productTile"));
  processInclude(require("./product/wishlistHeart"));
  processInclude(require("./product/tile"));

  var numberItems = $('input[name="numberItemsRecip"]').length > 0 ? parseInt($('input[name="numberItemsRecip"]')[0].value) : null;
  jQuery(".owl-carousel-rec-product").owlCarousel({
    loop:true,
    margin:10,
    nav:true,

    responsive:{
        0:{
            items:numberItems < 2 && numberItems != null ? numberItems : 2
        },
        900:{
            items:numberItems < 3 && numberItems != null ? numberItems : 3
        },
        1000:{
            items: numberItems < 6 && numberItems != null ? numberItems : 6
        }
    }
  })

  jQuery(".owl-carousel-main-banner").owlCarousel({
        items: 3,
        loop: true,
        nav: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsiveClass:true,
        responsiveBaseElement: 'body',
        items:1,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            },
        }
  });

      var newDiv = $("<div>").addClass("custom-owl-div");
      var div1 = $(".owl-carousel-main-banner .owl-nav");
      var div2 = $(".owl-carousel-main-banner .owl-dots");
      newDiv.append(div1, div2);
      $(".owl-carousel-main-banner").append(newDiv);
});


