/**
 * Show/hide wishlist hearth icon
 * @param {*} selector
 */
function updateTile(selector) {
    $(selector).children('.icon-heart-disabled').addClass('d-none');
    $(selector).children('.icon-heart-enabled').removeClass('d-none');
}

/**
 * Show product added icon
 * @param {*} selector
 */
function updateIconAddTile(selector) {
    $(selector).removeClass('d-none');
}

/**
 * Hide product added icon
 * @param {*} selector
 */
function hideIconAddTile(selector) {
    $(selector).addClass('d-none');
}


/**
 * Updatea the Wishlist icona on tiles
 * @param {*} pid
 */
function updateWishlistIcons(pid) {
    let items = $('.wishlist-json').data('wishlist');
    if (pid) {
        items.push(pid);
        $('.wishlist-json').data('wishlist', items);
    }
    if (items && items.length > 0) {
        items.forEach((item) => {
            updateTile(`.js-wishtile-${item}`);
        });
    }
}
/**
 * Update the product added icon on tiles
 * @param {*} pid
 */
function updateProductAddedIcons(pid) {
    let items = $('.cart-json').data('cart');
    if (pid) {
        items.itemsquantities.push({ id: pid, qty: 1 });
        $('.cart-json').data('cart', items);
    }
    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            updateIconAddTile(`.icon-added-${item.id}`);
        });
    }
}

module.exports = {
    changePLPView() {
        $(document).on('click', '.js-view-list', function toListView() {
            $('#itemsContainer .card').addClass('horizontal');
            $('.grid-list__right-header-view').removeClass('mosaic');
            $('.grid-list__right-header-view').addClass('list');
            $('.js-view-mosaic').removeClass('selected');
            $(this).addClass('selected');
        });

        $(document).on('click', '.js-view-mosaic', function toMosaicView() {
            $('#itemsContainer .card').removeClass('horizontal');
            $('.grid-list__right-header-view').removeClass('list');
            $('.grid-list__right-header-view').addClass('mosaic');
            $('.js-view-list').removeClass('selected');
            $(this).addClass('selected');
        });
    },
    updateWishlistIcons,
    updateProductAddedIcons,
    hideIconAddTile,
    showAttributesProductTextOnHover() {
        $('.tile-badge-circle').on('mouseover', function showAttributeValue() {
            const text = $(this).data('displayvalue');
            const tooltip = `<div class="tooltip-badges">${text}</div>`;
            $(this).closest('.card__header-list').append(tooltip);

            const elementOffset = $(this).offset();

            if ($(this).closest('.card__header-list')) {
                const containerOffset = $(this).closest('.card__header-list').offset();
                let tooltipTopPosition = `${elementOffset.top - containerOffset.top}px`;

                $('.tooltip-badges').css({
                    top: tooltipTopPosition
                });
            }
        });

        $('.tile-badge-circle').on('mouseout', () => {
            $('.tooltip-badges').remove();
        });
    },
    trackCitrusClick() {
        $(document).on('click', '.js-citrus-grid-banner', function sendCitrusClick(event) {
            event.preventDefault();
            const $link = $(this).find('a');
            const url = $link.attr('href');
            if (url) {
                window.location.href = url;
            }
            const backendUrl = $(this).attr('data-click-url');
            if (backendUrl) {
                $.ajax({
                    url: backendUrl,
                    method: 'GET'
                });
            }
        });
    }
};
