var tile = require('../product/tile');
const wishlistUtils = require('./wishlistUtils');
const citrus = require('../citrus');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    let status;
    if (data.success) {
        status = 'alert-success';
        if (icon.hasClass('fa-heart-o')) {
            icon.removeClass('fa-heart-o').addClass('fa-heart');
        }
    } else {
        status = 'alert-danger';
    }
    if ($('.add-to-wishlist-messages').length === 0) {
        const $couponModal = $('.couponmodal');
        const messageDiv = '<div class="add-to-wishlist-messages"></div>';

        if ($couponModal.length && $couponModal.is(':visible')) {
            $couponModal.append(messageDiv);
        } else {
            $('body').append(messageDiv);
        }
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

module.exports = {
    addToWishlist() {
        $('body').on('click', '.wishlistTile', function addWishlist(e) {
            e.preventDefault();
            const el = $(this);
            const icon = $(this).find($('i'));
            const url = $(this).attr('href');
            const pid = $(this).closest('.product').data('pid');
            let optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            let optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            const dataToSend = pid ? { pid, optionId, optionVal } : null;

            $.spinner().start();
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: dataToSend,
                success(data) {
                    if (data.error) {
                        displayMessageAndChangeIcon(data, icon);
                    } else if (data.listsIds) {
                        $.spinner().stop();
                        wishlistUtils.openModal(data, pid);
                    } else {
                        tile.updateWishlistIcons(pid);
                        displayMessageAndChangeIcon(data, icon);
                    }
                },
                error(err) {
                    displayMessageAndChangeIcon(err, icon);
                },
                complete() {
                    citrus.handleCictusClick(el);
                }
            });
        });

        $('body').on('click', '.addItemToSelectedWishList', function addWishListFromPopup(e) {
            e.preventDefault();
            const url = $(this).attr('href');
            const pid = $(this).data('pid');
            const icon = $(".product[data-pid='" + pid + "']").find($('i'));

            if (!url) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url,
                type: 'post',
                success(data) {
                    $.spinner().stop();
                    $('#wishListModal').modal('hide');
                    $('#wishListModalResponse').html('');
                    tile.updateWishlistIcons(pid);
                    displayMessageAndChangeIcon(data, icon);
                },
                error(err) {
                    displayMessageAndChangeIcon(err, icon);
                }
            });
        });
    }
};
